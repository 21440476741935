import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const HomeButton = ({
  to,
  onClick,
  activeKey,
}: {
  to: string;
  onClick: (e: number) => void;
  activeKey: number;
}) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <Link
      to={to}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => onClick(1)}
    >
      {activeKey === 1 ? (
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_233_6383)">
            <rect x="8" width="40" height="40" rx="4" fill="#466DF7" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.0967 10.0397C27.7931 9.98698 28.1966 9.98672 28.8843 10.0392C29.7384 10.1044 30.5423 10.4334 31.2049 10.9495L36.7836 15.2947C37.5343 15.8576 37.9867 16.7271 37.9999 17.6612L38 17.671L37.9999 26.0142C37.9999 28.2374 36.1427 30 33.9016 30H22.0983C19.8572 30 18 28.2374 18 26.0142V17.673C18.0095 16.7372 18.4628 15.8653 19.2164 15.3037L24.7601 10.9588C25.4265 10.4365 26.2366 10.1048 27.0967 10.0397ZM28.7781 11.4305C28.162 11.3835 27.8292 11.3836 27.202 11.4311C26.6328 11.4741 26.0827 11.6951 25.6209 12.0571L20.07 16.4075L20.0547 16.4192C19.6396 16.7264 19.4013 17.1942 19.3953 17.6839V26.0142C19.3953 27.4229 20.5832 28.6047 22.0983 28.6047H33.9016C35.4167 28.6047 36.6046 27.4229 36.6046 26.0142V17.6763C36.5963 17.1857 36.3575 16.7177 35.9431 16.4085L35.9315 16.3997L30.3475 12.0503C29.888 11.6924 29.3423 11.4736 28.7781 11.4305ZM28 20.9538C28.3853 20.9538 28.6976 21.2661 28.6976 21.6514V24.5205C28.6976 24.9059 28.3853 25.2182 28 25.2182C27.6147 25.2182 27.3023 24.9059 27.3023 24.5205V21.6514C27.3023 21.2661 27.6147 20.9538 28 20.9538Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_233_6383"
              x="0"
              y="0"
              width="56"
              height="56"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="8" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.490196 0 0 0 0 0.227451 0 0 0 0 0.827451 0 0 0 0.28 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_233_6383"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_233_6383"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      ) : (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" rx="4" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.0967 10.0397C19.7931 9.98698 20.1966 9.98672 20.8843 10.0392C21.7384 10.1044 22.5423 10.4334 23.2049 10.9495L28.7836 15.2947C29.5343 15.8576 29.9868 16.7271 29.9999 17.6612L30 17.671L29.9999 26.0142C29.9999 28.2374 28.1427 30 25.9016 30H14.0983C11.8572 30 10 28.2374 10 26.0142V17.673C10.0095 16.7372 10.4628 15.8653 11.2164 15.3037L16.7601 10.9588C17.4265 10.4365 18.2366 10.1048 19.0967 10.0397ZM20.7781 11.4305C20.162 11.3835 19.8292 11.3836 19.202 11.4311C18.6328 11.4741 18.0827 11.6951 17.6209 12.0571L12.07 16.4075L12.0547 16.4192C11.6396 16.7264 11.4013 17.1942 11.3953 17.6839V26.0142C11.3953 27.4229 12.5832 28.6047 14.0983 28.6047H25.9016C27.4167 28.6047 28.6046 27.4229 28.6046 26.0142V17.6763C28.5963 17.1857 28.3575 16.7177 27.9431 16.4085L27.9315 16.3997L22.3475 12.0503C21.888 11.6924 21.3423 11.4736 20.7781 11.4305ZM20 20.9538C20.3853 20.9538 20.6976 21.2661 20.6976 21.6514V24.5205C20.6976 24.9059 20.3853 25.2182 20 25.2182C19.6147 25.2182 19.3023 24.9059 19.3023 24.5205V21.6514C19.3023 21.2661 19.6147 20.9538 20 20.9538Z"
            fill={hovered ? '#466DF7' : 'black'}
          />
        </svg>
      )}
    </Link>
  );
};
export default HomeButton;
