import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const DatabaseButton = ({
  to,
  onClick,
  activeKey,
}: {
  to: string;
  onClick: (e: number) => void;
  activeKey: number;
}) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <Link
      to={to}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => onClick(3)}
    >
      {activeKey === 3 ? (
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_233_6388)">
            <rect x="8" width="40" height="40" rx="4" fill="#466DF7" />
            <path
              d="M37.3023 14.4186L-nan -nanL37.3023 14.4186C37.6592 14.4186 37.9529 14.6985 37.9654 15.0552C37.9818 15.5201 38 16.1707 38 16.7671V23.2329C38 24.3173 38 25.1767 37.9398 25.8677C37.8783 26.5748 37.7497 27.1711 37.4534 27.7101C37.0495 28.4448 36.4448 29.0495 35.7101 29.4534C35.1711 29.7497 34.5748 29.8783 33.8677 29.9398C33.1767 30 32.3173 30 31.2329 30H24.7671C23.6827 30 22.8233 30 22.1323 29.9398C21.4252 29.8783 20.8289 29.7497 20.2899 29.4534C19.5552 29.0495 18.9505 28.4448 18.5466 27.7101C18.2503 27.1711 18.1217 26.5748 18.0602 25.8677C18 25.1766 18 24.3173 18 23.2328V16.7672C18 15.6827 18 14.8234 18.0602 14.1323C18.1217 13.4252 18.2503 12.8289 18.5466 12.2899C18.9505 11.5552 19.5552 10.9505 20.2899 10.5466C20.8289 10.2503 21.4252 10.1217 22.1323 10.0602C22.8234 9.99999 23.6827 9.99999 24.7672 10H31.2328C31.8292 10 32.4799 10.0182 32.9448 10.0346C33.3015 10.0471 33.5814 10.3408 33.5814 10.6977L-nan -nanL33.5814 10.6977C33.5849 11.1032 33.2532 11.4319 32.8477 11.4247L31.2 11.3953H24.8C23.6754 11.3953 22.8768 11.396 22.2533 11.4503C21.6388 11.5038 21.2604 11.6053 20.9621 11.7693C20.4594 12.0457 20.0457 12.4594 19.7693 12.9621C19.6053 13.2604 19.5038 13.6388 19.4503 14.2533C19.396 14.8768 19.3953 15.6754 19.3953 16.8V23.2C19.3953 24.3246 19.396 25.1232 19.4503 25.7467C19.5038 26.3612 19.6053 26.7396 19.7693 27.0379C20.0457 27.5406 20.4594 27.9543 20.9621 28.2307C21.2604 28.3947 21.6388 28.4962 22.2533 28.5497C22.8768 28.604 23.6754 28.6047 24.8 28.6047H31.2C32.3246 28.6047 33.1232 28.604 33.7467 28.5497C34.3612 28.4962 34.7396 28.3947 35.0379 28.2307C35.5406 27.9543 35.9543 27.5406 36.2307 27.0379C36.3947 26.7396 36.4962 26.3612 36.5497 25.7467C36.604 25.1232 36.6047 24.3246 36.6047 23.2V16.8C36.6047 16.1519 36.6044 15.6121 36.5939 15.1516C36.5847 14.7527 36.9033 14.4152 37.3023 14.4186L-nan -nanL37.3023 14.4186Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M36 11.3333C35.6318 11.3333 35.3333 11.6318 35.3333 12C35.3333 12.3682 35.6318 12.6667 36 12.6667C36.3682 12.6667 36.6667 12.3682 36.6667 12C36.6667 11.6318 36.3682 11.3333 36 11.3333ZM34 12C34 10.8954 34.8954 10 36 10C37.1046 10 38 10.8954 38 12C38 13.1046 37.1046 14 36 14C34.8954 14 34 13.1046 34 12Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M28 15.25C28.4142 15.25 28.75 15.5858 28.75 16V24C28.75 24.4142 28.4142 24.75 28 24.75C27.5858 24.75 27.25 24.4142 27.25 24V16C27.25 15.5858 27.5858 15.25 28 15.25ZM33 17.4318C33.4142 17.4318 33.75 17.7676 33.75 18.1818V24C33.75 24.4142 33.4142 24.75 33 24.75C32.5858 24.75 32.25 24.4142 32.25 24V18.1818C32.25 17.7676 32.5858 17.4318 33 17.4318ZM23 19.9773C23.4142 19.9773 23.75 20.3131 23.75 20.7273V24C23.75 24.4142 23.4142 24.75 23 24.75C22.5858 24.75 22.25 24.4142 22.25 24V20.7273C22.25 20.3131 22.5858 19.9773 23 19.9773Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_233_6388"
              x="0"
              y="0"
              width="56"
              height="56"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="8" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.490196 0 0 0 0 0.227451 0 0 0 0 0.827451 0 0 0 0.28 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_233_6388"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_233_6388"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      ) : (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" rx="4" fill="white" />
          <path
            d="M29.3023 14.4186L-nan -nanL29.3023 14.4186C29.6592 14.4186 29.9529 14.6985 29.9654 15.0552C29.9818 15.5201 30 16.1707 30 16.7671V23.2329C30 24.3173 30 25.1767 29.9398 25.8677C29.8783 26.5748 29.7497 27.1711 29.4534 27.7101C29.0495 28.4448 28.4448 29.0495 27.7101 29.4534C27.1711 29.7497 26.5748 29.8783 25.8677 29.9398C25.1767 30 24.3173 30 23.2329 30H16.7671C15.6827 30 14.8233 30 14.1323 29.9398C13.4252 29.8783 12.8289 29.7497 12.2899 29.4534C11.5552 29.0495 10.9505 28.4448 10.5466 27.7101C10.2503 27.1711 10.1217 26.5748 10.0602 25.8677C9.99999 25.1766 9.99999 24.3173 10 23.2328V16.7672C9.99999 15.6827 9.99999 14.8234 10.0602 14.1323C10.1217 13.4252 10.2503 12.8289 10.5466 12.2899C10.9505 11.5552 11.5552 10.9505 12.2899 10.5466C12.8289 10.2503 13.4252 10.1217 14.1323 10.0602C14.8234 9.99999 15.6827 9.99999 16.7672 10H23.2328C23.8292 10 24.4799 10.0182 24.9448 10.0346C25.3015 10.0471 25.5814 10.3408 25.5814 10.6977L-nan -nanL25.5814 10.6977C25.5849 11.1032 25.2532 11.4319 24.8477 11.4247L23.2 11.3953H16.8C15.6754 11.3953 14.8768 11.396 14.2533 11.4503C13.6388 11.5038 13.2604 11.6053 12.9621 11.7693C12.4594 12.0457 12.0457 12.4594 11.7693 12.9621C11.6053 13.2604 11.5038 13.6388 11.4503 14.2533C11.396 14.8768 11.3953 15.6754 11.3953 16.8V23.2C11.3953 24.3246 11.396 25.1232 11.4503 25.7467C11.5038 26.3612 11.6053 26.7396 11.7693 27.0379C12.0457 27.5406 12.4594 27.9543 12.9621 28.2307C13.2604 28.3947 13.6388 28.4962 14.2533 28.5497C14.8768 28.604 15.6754 28.6047 16.8 28.6047H23.2C24.3246 28.6047 25.1232 28.604 25.7467 28.5497C26.3612 28.4962 26.7396 28.3947 27.0379 28.2307C27.5406 27.9543 27.9543 27.5406 28.2307 27.0379C28.3947 26.7396 28.4962 26.3612 28.5497 25.7467C28.604 25.1232 28.6047 24.3246 28.6047 23.2V16.8C28.6047 16.1519 28.6044 15.6121 28.5939 15.1516C28.5847 14.7527 28.9033 14.4152 29.3023 14.4186L-nan -nanL29.3023 14.4186Z"
            fill={hovered ? '#466DF7' : 'black'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28 11.3333C27.6318 11.3333 27.3333 11.6318 27.3333 12C27.3333 12.3682 27.6318 12.6667 28 12.6667C28.3682 12.6667 28.6667 12.3682 28.6667 12C28.6667 11.6318 28.3682 11.3333 28 11.3333ZM26 12C26 10.8954 26.8954 10 28 10C29.1046 10 30 10.8954 30 12C30 13.1046 29.1046 14 28 14C26.8954 14 26 13.1046 26 12Z"
            fill={hovered ? '#466DF7' : 'black'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 15.25C20.4142 15.25 20.75 15.5858 20.75 16V24C20.75 24.4142 20.4142 24.75 20 24.75C19.5858 24.75 19.25 24.4142 19.25 24V16C19.25 15.5858 19.5858 15.25 20 15.25ZM25 17.4318C25.4142 17.4318 25.75 17.7676 25.75 18.1818V24C25.75 24.4142 25.4142 24.75 25 24.75C24.5858 24.75 24.25 24.4142 24.25 24V18.1818C24.25 17.7676 24.5858 17.4318 25 17.4318ZM15 19.9773C15.4142 19.9773 15.75 20.3131 15.75 20.7273V24C15.75 24.4142 15.4142 24.75 15 24.75C14.5858 24.75 14.25 24.4142 14.25 24V20.7273C14.25 20.3131 14.5858 19.9773 15 19.9773Z"
            fill={hovered ? '#466DF7' : 'black'}
          />
        </svg>
      )}
    </Link>
  );
};
export default DatabaseButton;
