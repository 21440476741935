import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ConversationButton = ({
  to,
  onClick,
  activeKey,
}: {
  to: string;
  onClick: (e: number) => void;
  activeKey: number;
}) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <Link
      to={to}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => onClick(6)}
    >
      {activeKey === 6 ? (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" rx="4" fill="#466DF7" />
          <path
            d="M11.4999 28.4995C12.3299 29.3295 13.6699 29.3295 14.4999 28.4995L27.4999 15.4995C28.3299 14.6695 28.3299 13.3295 27.4999 12.4995C26.6699 11.6695 25.3299 11.6695 24.4999 12.4995L11.4999 25.4995C10.6699 26.3295 10.6699 27.6695 11.4999 28.4995Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.0098 16.9902L23.0098 13.9902"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.5 10.44L18 10L17.56 11.5L18 13L16.5 12.56L15 13L15.44 11.5L15 10L16.5 10.44Z"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.5 16.44L14 16L13.56 17.5L14 19L12.5 18.56L11 19L11.44 17.5L11 16L12.5 16.44Z"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.5 21.44L29 21L28.56 22.5L29 24L27.5 23.56L26 24L26.44 22.5L26 21L27.5 21.44Z"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M3.49994 20.4995C4.32994 21.3295 5.66994 21.3295 6.49994 20.4995L19.4999 7.49945C20.3299 6.66945 20.3299 5.32945 19.4999 4.49945C18.6699 3.66945 17.3299 3.66945 16.4999 4.49945L3.49994 17.4995C2.66994 18.3295 2.66994 19.6695 3.49994 20.4995Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={hovered ? '#466DF7' : 'black'}
          />
          <path
            d="M18.0098 8.99023L15.0098 5.99023"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={hovered ? '#466DF7' : 'black'}
          />
          <path
            d="M8.5 2.44L10 2L9.56 3.5L10 5L8.5 4.56L7 5L7.44 3.5L7 2L8.5 2.44Z"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={hovered ? '#466DF7' : 'black'}
          />
          <path
            d="M4.5 8.44L6 8L5.56 9.5L6 11L4.5 10.56L3 11L3.44 9.5L3 8L4.5 8.44Z"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={hovered ? '#466DF7' : 'black'}
          />
          <path
            d="M19.5 13.44L21 13L20.56 14.5L21 16L19.5 15.56L18 16L18.44 14.5L18 13L19.5 13.44Z"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={hovered ? '#466DF7' : 'black'}
          />
        </svg>
      )}
    </Link>
  );
};
export default ConversationButton;
