import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Sider from 'antd/lib/layout/Sider';
import { Menu } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { SupersetTheme, useTheme } from '@superset-ui/core';
import { TourClickedIndex } from 'src/tourGuide/guideWidgetClickAction';
import { useLocation } from 'react-router-dom';
import HomeButton from './svgComponents/HomeButton';
import SearchButton from './svgComponents/SearchButton';
import DatabaseButton from './svgComponents/DatabaseButton';
import NotificationButton from './svgComponents/NotificationButton';
import SettingButton from './svgComponents/SettingButton';
import ConversationButton from './svgComponents/ConversationButton';

const SidebarMenu = () => {
  const session = uuidv4();
  const theme: SupersetTheme = useTheme();
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState<number>(2);
  const [isShow, setIsShow] = useState<boolean>(true);
  const onClick = (e: number) => {
    dispatch(TourClickedIndex(10));
    setActiveKey(e);
  };
  const location = useLocation();
  useEffect(() => {
    const { pathname } = location;
    if (pathname === '/quotron/home/') setActiveKey(1);
    else if (pathname === '/quotron/search/') setActiveKey(2);
    else if (pathname === '/quotron/tables/') setActiveKey(3);
    else if (pathname === '/report/list/') setActiveKey(4);
    else if (pathname === '/import_data/') setActiveKey(5);
    else if (pathname.includes('/quotron/conversation/')) setActiveKey(6);
    else if (
      pathname === '/signup/' ||
      pathname === '/start/' ||
      pathname === '/congratulation/' ||
      pathname === '/signin/'
    )
      setIsShow(false);
    else setActiveKey(0);
  }, [location, dispatch]);

  return isShow ? (
    <Sider
      collapsed
      theme="light"
      collapsedWidth={64}
      style={{
        position: 'fixed',
        left: 0,
        height: 'calc(100vh - 45px)',
      }}
    >
      <Menu
        defaultOpenKeys={['1']}
        inlineIndent={12}
        mode="inline"
        style={{
          background: theme.colors.quotron.white,
          height: '100%',
        }}
      >
        <Menu.Item
          key="1"
          style={{
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <HomeButton
            to="/quotron/home/"
            onClick={onClick}
            activeKey={activeKey}
          />
        </Menu.Item>
        <Menu.Item
          key="2"
          style={{
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <SearchButton
            to="/quotron/search/"
            onClick={onClick}
            activeKey={activeKey}
          />
        </Menu.Item>
        <Menu.Item
          key="3"
          style={{
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            width: '95%',
          }}
        >
          <DatabaseButton
            to="/quotron/tables/"
            onClick={onClick}
            activeKey={activeKey}
          />
        </Menu.Item>
        <Menu.Item
          key="6"
          style={{
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            width: '95%',
          }}
        >
          <ConversationButton
            to={`/quotron/conversation/${session}`}
            onClick={onClick}
            activeKey={activeKey}
          />
        </Menu.Item>
        <Menu.Item
          key="4"
          style={{
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            bottom: 70,
            width: '95%',
          }}
        >
          <NotificationButton
            to="/report/list/"
            onClick={onClick}
            activeKey={activeKey}
          />
        </Menu.Item>
        <Menu.Item
          key="5"
          style={{
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            bottom: 20,
            width: '95%',
          }}
        >
          <SettingButton
            to="/import_data/"
            onClick={onClick}
            activeKey={activeKey}
          />
        </Menu.Item>
      </Menu>
    </Sider>
  ) : null;
};

export default SidebarMenu;
