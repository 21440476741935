import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const NotificationButton = ({
  to,
  onClick,
  activeKey,
}: {
  to: string;
  onClick: (e: number) => void;
  activeKey: number;
}) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <Link
      to={to}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => onClick(4)}
    >
      {activeKey === 4 ? (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" rx="4" fill="#466DF7" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 10C16.0184 10 12.7907 13.2277 12.7907 17.2093V23.7209C12.7907 24.1062 13.1031 24.4186 13.4884 24.4186C13.8737 24.4186 14.186 24.1062 14.186 23.7209V17.2093C14.186 13.9983 16.789 11.3953 20 11.3953C20.7416 11.3953 21.4508 11.5342 22.103 11.7874C22.2423 11.3184 22.5154 10.9069 22.8781 10.5974C21.9965 10.2131 21.0231 10 20 10ZM26.4742 14.034C26.1806 14.4113 25.7813 14.7022 25.3207 14.8622C25.6378 15.58 25.814 16.3741 25.814 17.2093V23.7209C25.814 24.1062 26.1263 24.4186 26.5116 24.4186C26.8969 24.4186 27.2093 24.1062 27.2093 23.7209V17.2093C27.2093 16.0698 26.9449 14.992 26.4742 14.034ZM10.6977 25.3488C10.3124 25.3488 10 25.6612 10 26.0465C10 26.4318 10.3124 26.7442 10.6977 26.7442H29.3023C29.6876 26.7442 30 26.4318 30 26.0465C30 25.6612 29.6876 25.3488 29.3023 25.3488H10.6977ZM19.0698 28.6047C18.6845 28.6047 18.3721 28.917 18.3721 29.3023C18.3721 29.6876 18.6845 30 19.0698 30H20.9302C21.3155 30 21.6279 29.6876 21.6279 29.3023C21.6279 28.917 21.3155 28.6047 20.9302 28.6047H19.0698Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.5 11.5C23.9477 11.5 23.5 11.9477 23.5 12.5C23.5 13.0523 23.9477 13.5 24.5 13.5C25.0523 13.5 25.5 13.0523 25.5 12.5C25.5 11.9477 25.0523 11.5 24.5 11.5ZM22.5 12.5C22.5 11.3954 23.3954 10.5 24.5 10.5C25.6046 10.5 26.5 11.3954 26.5 12.5C26.5 13.6046 25.6046 14.5 24.5 14.5C23.3954 14.5 22.5 13.6046 22.5 12.5Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" rx="4" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 10C16.0184 10 12.7907 13.2277 12.7907 17.2093V23.7209C12.7907 24.1062 13.1031 24.4186 13.4884 24.4186C13.8737 24.4186 14.186 24.1062 14.186 23.7209V17.2093C14.186 13.9983 16.789 11.3953 20 11.3953C20.7416 11.3953 21.4508 11.5342 22.103 11.7874C22.2423 11.3184 22.5154 10.9069 22.8781 10.5974C21.9965 10.2131 21.0231 10 20 10ZM26.4742 14.034C26.1806 14.4113 25.7813 14.7022 25.3207 14.8622C25.6378 15.58 25.814 16.3741 25.814 17.2093V23.7209C25.814 24.1062 26.1263 24.4186 26.5116 24.4186C26.8969 24.4186 27.2093 24.1062 27.2093 23.7209V17.2093C27.2093 16.0698 26.9449 14.992 26.4742 14.034ZM10.6977 25.3488C10.3124 25.3488 10 25.6612 10 26.0465C10 26.4318 10.3124 26.7442 10.6977 26.7442H29.3023C29.6876 26.7442 30 26.4318 30 26.0465C30 25.6612 29.6876 25.3488 29.3023 25.3488H10.6977ZM19.0698 28.6047C18.6845 28.6047 18.3721 28.917 18.3721 29.3023C18.3721 29.6876 18.6845 30 19.0698 30H20.9302C21.3155 30 21.6279 29.6876 21.6279 29.3023C21.6279 28.917 21.3155 28.6047 20.9302 28.6047H19.0698Z"
            fill={hovered ? '#466DF7' : 'black'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.5 11.5C23.9477 11.5 23.5 11.9477 23.5 12.5C23.5 13.0523 23.9477 13.5 24.5 13.5C25.0523 13.5 25.5 13.0523 25.5 12.5C25.5 11.9477 25.0523 11.5 24.5 11.5ZM22.5 12.5C22.5 11.3954 23.3954 10.5 24.5 10.5C25.6046 10.5 26.5 11.3954 26.5 12.5C26.5 13.6046 25.6046 14.5 24.5 14.5C23.3954 14.5 22.5 13.6046 22.5 12.5Z"
            fill={hovered ? '#466DF7' : 'black'}
          />
        </svg>
      )}
    </Link>
  );
};
export default NotificationButton;
