import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const SearchButton = ({
  to,
  onClick,
  activeKey,
}: {
  to: string;
  onClick: (e: number) => void;
  activeKey: number;
}) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <Link
      to={to}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => onClick(2)}
    >
      {activeKey === 2 ? (
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_233_6311)">
            <rect x="8" width="40" height="40" rx="4" fill="#466DF7" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.0698 11.3953C22.8313 11.3953 19.3953 14.8313 19.3953 19.0698C19.3953 23.3082 22.8313 26.7442 27.0698 26.7442C31.3082 26.7442 34.7442 23.3082 34.7442 19.0698C34.7442 14.8313 31.3082 11.3953 27.0698 11.3953ZM18 19.0698C18 14.0607 22.0607 10 27.0698 10C32.0789 10 36.1395 14.0607 36.1395 19.0698C36.1395 21.3232 35.3177 23.3847 33.9575 24.9708L37.7957 28.809C38.0681 29.0815 38.0681 29.5232 37.7957 29.7957C37.5232 30.0681 37.0815 30.0681 36.809 29.7957L32.9708 25.9575C31.3847 27.3177 29.3232 28.1395 27.0698 28.1395C22.0607 28.1395 18 24.0789 18 19.0698Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_233_6311"
              x="0"
              y="0"
              width="56"
              height="56"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="8" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.490196 0 0 0 0 0.227451 0 0 0 0 0.827451 0 0 0 0.28 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_233_6311"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_233_6311"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      ) : (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" rx="4" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.0698 11.3953C14.8313 11.3953 11.3953 14.8313 11.3953 19.0698C11.3953 23.3082 14.8313 26.7442 19.0698 26.7442C23.3082 26.7442 26.7442 23.3082 26.7442 19.0698C26.7442 14.8313 23.3082 11.3953 19.0698 11.3953ZM10 19.0698C10 14.0607 14.0607 10 19.0698 10C24.0789 10 28.1395 14.0607 28.1395 19.0698C28.1395 21.3232 27.3177 23.3847 25.9575 24.9708L29.7957 28.809C30.0681 29.0815 30.0681 29.5232 29.7957 29.7957C29.5232 30.0681 29.0815 30.0681 28.809 29.7957L24.9708 25.9575C23.3847 27.3177 21.3232 28.1395 19.0698 28.1395C14.0607 28.1395 10 24.0789 10 19.0698Z"
            fill={hovered ? '#466DF7' : 'black'}
          />
        </svg>
      )}
    </Link>
  );
};
export default SearchButton;
